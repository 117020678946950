export const DESCRIPTIONS = {
  additionalInfo: 'Estos datos se le solicitarán al cliente al momento del pago.',
  externalId: 'Puedes asignarle un identificador único propio al producto.',
};

export const SUBSCRIPTIONS_DESCRIPTIONS = {
  pending:
    'Una suscripción pendiente indica que el cliente todavía no ha inscrito su tarjeta o efectuado el pago.',
  active: 'Una suscripción cobro activa indica que se está cobrando recurrentemente.',
  indebt:
    'Una suscripción en deuda indica que tu cliente tuvo un problema al pagar, te recomendamos contactarte con esta persona.',
  canceled:
    'Una suscripción cancelada indica que, ya sea por petición del cliente o tu iniciativa, se canceló la suscripción o cobro, por lo que no se cobrará.',
  active_pending:
    'Una suscripción en cola indica que el cliente ya inscribió su tarjeta, pero la suscripción no se cobrará hasta la fecha del primer pago.',
  finished:
    'Una suscripción terminada indica que ya se han cobrado todas las cuotas definidas en el servicio.',
  price:
    'El precio de la suscripción puede ser seteado manualmente en caso de no agregar productos al servicio. En caso contrario, el precio es la suma de los valores de los productos escogidos.',
  recurrence: 'Es la periodicidad del cobro de la suscripción.',
  duration:
    'Este campo define si la suscripción tiene una duración definida o permanecerá activa hasta su cancelación.',
  dop: 'Este campo define un día de cobro fijo del mes para todos los clientes de la suscripción. En caso de no fijar un día de cobro, el cobro a cada cliente dependerá del día en que el cliente se inscriba en la suscripción. De todas formas, se cobrará la la primera cuota el día que se inscriba el cliente con prorrateo o no, a menos que definas una fecha para el primer cobro.',
  expiration:
    'En este campo puedes definir una fecha límite en la cual se dejen de aceptar inscripciones a la suscripción.',
  startAt:
    'En este campo puedes definir una fecha en la que se comenzará a cobrar a los clientes de la suscripción. En otro caso, el primer cobro se realizará cuando un cliente se inscriba en la suscripción.',
  cumulativeDebt:
    'Si la deuda es acumulable, los clientes de la suscripción acumularán deuda al pasar la fecha de expiración de la cuota. En caso de no acumular deuda, no se seguirán creando cuotas si es que el cliente tiene una cuota vencida.',
  apportion:
    'El prorrateo es cobrar el monto proporcional de la primera cuota dependiendo de la fecha de la inscripción al servicio. Por ejemplo, eliges que tu servicio sea mensual y se pague los 15. Si tu cliente se inscribió un 5, en la primera cuota se le cobrará un proporcional a esos 10 días. En caso contrario, se le cobrará el monto de la suscripción entera independiente del día que se inscriba tu cliente.',
  inscriptionsLimit:
    'En este campo puedes definir una cantidad máxima de incripciones a este servicio.',
  cancelDebt:
    'En este campo puedes definir si quieres que se cancelen las inscripciones que no pagaron la primera cuota después del tiempo que tú definas. Esto ocurre cuando un cliente se inscribe y no finaliza el proceso de pago, generando una cuota que no fue pagada en el sistema.',
  rut: 'Se validará que el rut ingresado sea válido. Además sirve como identificador único para evitar duplicar inscripciones.',
  ask_shipping_address:
    'Se pedirá la dirección de despacho cuando los clientes se suscriban al servicio. Incluye región, comuna y dirección. Por defecto se toman las regiones y comunas seleccionadas en la configuración de despachos de tu cuenta.',
};

export const SINGLE_PAYMENT_DESCRIPTIONS = {
  price:
    'El precio del pago único puede ser seteado manualmente en caso de no agregar productos al servicio. En caso contrario, el precio es la suma de los valores de los productos escogidos.',
  limit:
    'Puedes elegir limitar el pago único. Por stock, en este caso no se aceptarán más pagos una vez se llegue al stock que tu definas. O por fecha, donde no se aceptarán más pagos después de la fecha que definas.',
  basket: 'Puedes permitir que los clientes compren más de una unidad en el mismo pago.',
  rut: 'Se validará que el rut ingresado sea válido.',
  ask_shipping_address:
    'Se pedirá la dirección de despacho cuando los clientes paguen el servicio. Incluye región, comuna y dirección. Por defecto se toman las regiones y comunas seleccionadas en la configuración de despachos de tu cuenta.',
};

export const STATEMENTS_DESCRIPTIONS = {
  pending:
    'Un pago en deuda indica que debe ser cobrado todavía, ya sea porque el cliente no ha efectuado el pago o porque no se ha alcanzado la fecha del siguiente cobro',
  paid: 'Un pago pagado, indica que el pago ha sido efectuado',
};

export const TITLE_ONBOARDING = {
  choice: '¿Tipo de Persona?',
  company: '¡Bienvenido!',
  bank: '¡Esto tambien es importante!',
};

export const SUBTITLE_ONBOARDING = {
  choice: 'Cuéntanos qué tipo de persona desear ingresar',
  company:
    'Es importante que contemos con tu información personal para poder contactarnos contigo y también tus clientes.',
  bank: 'Es relevante que contemos con tus datos bancarios, para que tus clientes puedan realizar el pago de forma exitosa.',
};

export const BASKET_DESCRIPTION = {
  basketable:
    'Un producto con canasta permitirá al cliente comprar varios productos al mismo tiempo.',
  notBasketable: 'Un producto sin canasta permitirá al cliente pagar por el producto una vez.',
};

export const COMPANY_DESCRIPTIONS = {
  admin:
    'Tiene acceso a la sección actual de configuración de usuarios. Por lo tanto puede crear, editar y eliminar usuarios, como también editar el envío de correos a los usuarios de la plataforma.',
};
