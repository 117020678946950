import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from '../../../app/hooks';
import { formatCurrency, setDocumentTitle } from '../../../common/utils';
import { useSnackbar } from 'notistack';

import { sellerApi } from '../../../common/api';
import { useMediaQuery, Typography, Button } from '@mui/material';

import { Statement, Buyer } from '../../../app/type';
import { SellerState, setBuyer } from '../sellerSlice';
import ResourceList from '../../../common/components/ResourceList';
import StatusLabel from '../../../common/components/StatusLabel';
import PopUp from '../../../common/components/PopUp';

import { faEye } from '@fortawesome/free-regular-svg-icons';
import variables from '../../../common/styles/variables.module.scss';
import { faMoneyBill1 } from '@fortawesome/free-solid-svg-icons';
import { PAYABLE_TYPES } from '../../../common/constants/statements';

const StatementPage = (): React.ReactElement => {
  setDocumentTitle('Pagos');
  const { company } = useSelector(({ seller }: { seller: SellerState }) => seller);
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedStatement, setSelectedStatement] = useState<Statement>();
  const [openRefundPopup, setOpenRefundPopup] = useState<boolean>(false);

  useEffect(() => {
    if (!company) {
      history.replace('/seller/home');
    }
  }, [company]);

  const onItem = (res: Buyer | undefined) => dispatch(setBuyer(res));

  const refundStatementAction = (statement: Statement) => {
    setOpenRefundPopup(true);
    setSelectedStatement(statement);
  };

  const refundStatement = (status: string) => {
    setOpenRefundPopup(false);
    enqueueSnackbar('Estamos procesando tu solicitud', {
      variant: 'success',
    });
    sellerApi.statements
      .refund(selectedStatement?.id || '', selectedStatement?.group_id, status)
      .then((response) => {
        enqueueSnackbar(
          response.message === 'kushki'
            ? 'La devolución está siendo procesada por el proveedor'
            : status === 'refund'
            ? 'Pago reembolsado exitosamente'
            : 'Solicitud procesada con éxito',
          {
            variant: 'success',
          }
        );
      })
      .catch((reason) => {
        let text = '';
        if (reason.response.data.message === 'fintoc not allowed')
          text = 'Fintoc no está habilitado para realizar devoluciones';
        if (reason.response.data.message === 'expired_time')
          text = 'Han pasado más de 14 días desde que se hizo el pago';
        if (reason.response.data.message === 'exceed_amount')
          text = 'No tienes saldo suficiente para devolver el monto';
        if (reason.response.data.message === 'not_credit_card')
          text = 'Solo se pueden hacer devoluciones de tarjetas de crédito';
        enqueueSnackbar(text || 'No pudimos devolver el pago, inténtalo más tarde', {
          variant: 'error',
        });
      })
      .finally(() => {
        setSelectedStatement(undefined);
      });
  };

  return (
    <Fragment>
      <ResourceList
        title="Pagos"
        queryFields="Nombre, correo, servicio"
        getResourceList={sellerApi.statements.list}
        resourceParent={company}
        listHeaders={[
          { key: 'service_name', label: 'Servicio' },
          { key: 'buyer', label: 'Cliente' },
          { key: 'payable_type', label: 'Tipo' },
          { key: 'value', label: 'Valor' },
          { key: 'due_date', label: 'Fecha de vencimiento' },
          { key: 'payment_date', label: 'Fecha de pago' },
          { key: 'status', label: 'Estado' },
        ]}
        listMobileHeaders={[{ key: 'service_name' }, { key: 'buyer' }]}
        listColumns={{
          service_name: (res: Statement) =>
            isMobile ? (
              <Typography variant="subtitle1">{res.service_name}</Typography>
            ) : (
              res.service_name
            ),
          buyer: (res: Statement) =>
            isMobile ? (
              <Typography variant="subtitle1">{res.buyer?.name}</Typography>
            ) : (
              res.buyer?.name
            ),
          payable_type: (res: Statement) => PAYABLE_TYPES[res.payable_type],
          value: (res: Statement) =>
            formatCurrency(res.currency === 'UF' ? res.external_amount : res.amount, res.currency),
          status: (res: Statement) => <StatusLabel type="statement" status={res.status} />,
          due_date: (res: Statement) => res.due_date,
          payment_date: (res: Statement) => res.payment_date,
        }}
        listActionsHeaders={(res) => [
          { key: 'show', label: 'Ver Cliente', icon: faEye },
          ['Fintoc', 'Método externo'].includes(res?.payment_type_translated || 'none') ||
          ['refunded', 'expired', 'pending', 'processing_refund', 'refunded_manually'].includes(
            res?.status || ''
          )
            ? null
            : { key: 'refund', label: 'Devolución', icon: faMoneyBill1 },
        ]}
        listActions={{
          show: (res: Statement) => {
            onItem(res.buyer);
            history.push(`/seller/buyers/${res.buyer?.id}`);
          },
          refund: (res: Statement) => refundStatementAction(res),
        }}
        excelDownloadMethod={(query, parentId, filterParams) =>
          sellerApi.statements.statementsExcel('company', parentId, query, filterParams)
        }
        filtersKey="statement_index"
        persistFilters
      />
      <PopUp
        state={{ open: openRefundPopup, setOpen: setOpenRefundPopup }}
        title={
          <Typography variant="h6" align="center">
            Devolución pago
          </Typography>
        }
        content={
          <>
            <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
              Para devolver un pago tienes dos opciones, que la plataforma procese la devolución a
              través del medio de pago con el que se realizó el pago. O marcar el pago como
              &quot;Devuelto manualmente&quot; y que tú gestiones la devolución por fuera de la
              plataforma.
            </Typography>
          </>
        }
        extraActions={[
          <Button onClick={() => setOpenRefundPopup(false)} variant="outlined" key={1}>
            Cancelar
          </Button>,
          <Button
            color="info"
            variant="contained"
            onClick={() => refundStatement('refund')}
            key={2}
          >
            Devolver cuota
          </Button>,
          <Button
            onClick={() => {
              refundStatement('refund_manually');
            }}
            color="info"
            variant="contained"
            key={3}
          >
            Marcar como devuelta
          </Button>,
        ]}
      />
    </Fragment>
  );
};

export default StatementPage;
