import React, { useState, useRef, useEffect, useMemo, Fragment, useImperativeHandle } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FormikErrors, FormikProps, useFormik } from 'formik';
import * as yup from 'yup';
import { es } from 'yup-locales';
import * as Sentry from '@sentry/react';
import { mapValues } from 'lodash';
import TagManager from 'react-gtm-module';
import { omit } from 'lodash';

yup.setLocale(es);

// Types
import {
  ApiObject,
  Buyer,
  ApiList,
  Suggestion,
  Payable,
  ShippingInformation,
  SinglePaymentProduct,
  SubscriptionProduct,
  GenericObject,
  Discount,
  CheckoutProduct,
} from '../../app/type';

// API
import { buyerApi } from '../../common/api';

// Utils
import { downcase, setDocumentTitle, useQuery, validateRut } from '../../common/utils';

// Components
import {
  Container,
  Typography,
  Button,
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';

// Assets
import styles from './Payment.module.scss';
import variables from '../../common/styles/variables.module.scss';
import PaymentSummary from './components/PaymentSummary';

import Avatar from '../../common/components/Avatar';
import PaymentGateways from './PaymentGateways';
import BuyerSuggestion from './BuyerSuggestion';
import { navigateTop } from '../../common/utils/index';

import AddPaymentMethods from '../../common/highOrderComponents/PaymentMethods/PaymentMethods';
import { NewComponentProps } from '../../common/highOrderComponents/PaymentMethods/PaymentMethods';
import FormSelector from './components/Forms/Forms';
import ShopifyOrder from './components/ShopifyOrder/ShopifyOrder';
import ShopifyPayment from './components/ShopifyPayment/ShopifyPayment';

interface BuyerInfo extends Omit<Buyer, 'id'> {
  id?: string;
}

let shippingQueryTimeout: ReturnType<typeof setTimeout>;

const Payment = (props: NewComponentProps): React.ReactElement => {
  const {
    productId,
    groupId,
    singlePaymentBuyerId,
    subscriptionBuyerId,
    paymentPlanId,
    subscriptionId,
    singlePaymentId,
    checkoutId,
  } = useParams<{
    productId: string;
    groupId: string;
    singlePaymentBuyerId: string;
    subscriptionBuyerId: string;
    paymentPlanId: string;
    subscriptionId: string;
    singlePaymentId: string;
    checkoutId: string;
  }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [cardRegistered, setCardRegistered] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const bottomRef = useRef(null);
  const [basketAmount, setBasketAmount] = useState<number>(1);
  const [buyerSuggestionOpen, setBuyerSuggestion] = useState<boolean>(false);
  const [buyersToDisplay, setPossibleBuyersToDisplay] = useState<Suggestion[]>([]);
  const [suggestionSelected, setSuggestionSelected] = useState<Suggestion | null>();
  const [selectedBuyerSubscription, setSubscriptionSelection] = useState<string | null>(null);
  const mpEmailRef = useRef(null);
  const [previousName, setPreviousName] = useState<string>();
  const [loadingPayment, setLoadingPayment] = useState<boolean>(false);
  const [payable, setPayable] = useState<Payable>();
  const [currPaymentMethod, setCurrPaymentMethod] = useState<string>('');
  const [customRecurrence, setCustomRecurrence] = useState<string | null>(null);
  const [customRecurrenceAmount, setCustomRecurrenceAmount] = useState<number | null>(null);
  const [customRecurrenceErrors, setCustomRecurrenceErrors] = useState<{
    [key: string]: string | null;
  }>({});
  const query = useQuery();
  const [formikToValidate, setFormikToValidate] = useState<FormikProps<ShippingInformation> | null>(
    null
  );
  const [shippingCost, setShippingCost] = useState<number | null>(null);
  const [activeStep, setActiveStep] = useState<string>('buyer_data');
  const [loadingStock, setLoadingStock] = useState<boolean>(false);
  const [disableNextStep, setDisableNextStep] = useState<boolean>(false);
  const steps = ['buyer_data', 'payment'];
  const [loadingShippingCost, setLoadingShippingCost] = useState<boolean>(false);
  const [suggestionOffered, setSuggestionOffered] = useState<boolean>(false);
  const [loadingDiscount, setLoadingDiscount] = useState<boolean>(false);
  const [extraDiscount, setExtraDiscount] = useState<Partial<Discount>>();
  const [validExtraDiscount, setValidExtraDiscount] = useState<boolean>();
  const [allowCustomCheckout, setAllowCustomChekout] = useState<boolean>(false);
  const [productsToSubscribe, setProductsToSubscribe] = useState<string[]>();
  const [codeDiscountValue, setCodeDiscountValue] = useState<string>();

  useEffect(() => {
    if (payable) {
      if (checkoutId || payable?.integration_kind == 'shopify') setAllowCustomChekout(true);
      if (checkoutId && !productsToSubscribe) {
        setProductsToSubscribe(
          payable.payable_products?.filter((cp) => cp.subscribable === true).map((cp) => cp.id)
        );
      }
      if (payable.ask_shipping_address) setFormikToValidate(shippingFormik);
    }
  }, [payable]);

  useEffect(() => {
    setLoading(true);
    let request;
    if (productId) {
      request = buyerApi.products.show;
    } else if (groupId) {
      request = buyerApi.groups.show;
    } else if (singlePaymentBuyerId) {
      request = buyerApi.singlePaymentBuyers.show;
    } else if (subscriptionBuyerId) {
      request = buyerApi.subscriptionBuyers.show;
    } else if (paymentPlanId) {
      request = buyerApi.paymentPlans.show;
    } else if (subscriptionId) {
      request = buyerApi.subscriptions.show;
    } else if (singlePaymentId) {
      request = buyerApi.singlePayments.show;
    } else if (checkoutId) {
      request = buyerApi.checkouts.show;
    } else {
      return;
    }
    request(
      productId ||
        groupId ||
        singlePaymentBuyerId ||
        subscriptionBuyerId ||
        paymentPlanId ||
        subscriptionId ||
        singlePaymentId ||
        checkoutId,
      { origin: query?.origin }
    )
      .then((data: ApiObject<Payable>) => {
        if (data.data.redirect_to && !query.enroll_payment) {
          history.replace(data.data.redirect_to);
          return;
        }
        setPayable(data.data);
      })
      .catch((error: any) => {
        Sentry.captureException(error);
        enqueueSnackbar('Ocurrió un error cargando el servicio', { variant: 'error' });
        history.replace('/subscription/failed');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    productId,
    groupId,
    singlePaymentBuyerId,
    subscriptionBuyerId,
    paymentPlanId,
    subscriptionId,
    singlePaymentId,
    checkoutId,
    query,
  ]);

  useEffect(() => {
    payable?.company?.block === 'platform' && history.replace('/subscription/blocked_company');
  }, [payable?.company]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const buyerSchema = useMemo(() => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let lastNameBase = yup.string().min(2).max(100).label('Apellido');
    lastNameBase = allowCustomCheckout ? lastNameBase.required() : lastNameBase;

    return yup.object().shape({
      name: yup.string().min(3).max(100).required().label('Nombre'),
      last_name: lastNameBase,
      email: yup
        .string()
        .email()
        .matches(emailRegex, 'Email inválido, no puede contener caracteres especiales')
        .required()
        .label('Email')
        .test('gmail-domain', 'Dominio del correo inválido, debe terminar en .com', (value) => {
          if (!value) return true;
          const domain = value.split('@')[1];
          if (domain && (domain.startsWith('gmail') || domain.startsWith('hotmail'))) {
            return domain === 'gmail.com' || domain === 'hotmail.com';
          }
          return true;
        }),
    });
  }, [payable?.title, allowCustomCheckout]);

  const shippingSchema = useMemo(() => {
    const phoneRegex = /^[0-9]*$/;

    return yup.object().shape({
      address1: yup.string().required().label('Dirección'),
      address2: yup.string().label('Dirección 2'),
      country: yup.string().equals(['Chile']).required(),
      region: yup.string().required().label('Región'),
      commune: yup.string().required().label('Comuna'),
      phone: yup
        .string()
        .required()
        .matches(phoneRegex, 'El número solo puede contener números')
        .length(9, 'Debe ser de 9 dígitos')
        .label('Teléfono'),
    });
  }, [payable]);

  const buyerShippingSchema = useMemo(() => {
    return yup.object().shape({
      address1: yup.string().required().label('Dirección'),
      region: yup.string().required().label('Región'),
      commune: yup.string().required().label('Comuna'),
    });
  }, [payable]);

  useEffect(() => {
    switch (payable?.integration_kind) {
      case 'shopify' || allowCustomCheckout:
        setFormikToValidate(shippingFormik);
        setLoadingStock(true);
        buyerApi.shopifyProducts
          .variantStock(payable.payable_products?.map((value) => value.product.external_id))
          .then((stock) => {
            if (typeof stock.stock === 'string') {
              if (stock.stock === 'offline_stock') {
                return;
              }
            } else {
              const newProducts = Object.entries(stock.stock).map(([key, value]) => {
                let prod: SinglePaymentProduct | SubscriptionProduct | CheckoutProduct | undefined;
                payable.payable_products?.forEach((p) =>
                  p.product.external_id.split('_')[0] === key.toString() ? (prod = p) : null
                );
                prod ? (prod.product['stock'] = value) : null;
                return prod || ({} as SubscriptionProduct);
              });
              payable.payable_products &&
                setPayable({ ...payable, payable_products: [...newProducts] });
            }
          })
          .finally(() => setLoadingStock(false));
        break;
      default:
        null;
    }
  }, [payable?.integration_kind]);

  const formikIsValid = async () => {
    if (formikToValidate) {
      const hasErrors = await formikToValidate
        .validateForm()
        .then((errors: FormikErrors<ShippingInformation>) => {
          if (Object.keys(errors).length === 0) {
            return true;
          }
          formikToValidate.setErrors(errors);
          Object.keys(errors).forEach((key) => {
            formikToValidate.setFieldTouched(key, true);
          });
          return false;
        });
      return hasErrors;
    }
  };

  const retrieveProductsQuantities = () => {
    const returnObject: { [key: string]: number } = {};
    payable?.payable_products?.forEach((value) => {
      returnObject[value.id] = value.quantity;
    });
    return returnObject;
  };

  const submitForm = async (buyerInfo: Partial<BuyerInfo>, { setErrors }: any) => {
    setLoadingPayment(true);
    const args = {
      data: buyerInfo,
      basket_amount: basketAmount,
      custom_recurrence: customRecurrence,
      custom_recurrence_amount: customRecurrenceAmount,
      discount_code: extraDiscount?.external_id,
      extra_data: {
        ...(props.extraArgs?.cardData || {}),
        ...(props.extraArgs?.kushkiInfo || {}),
        card_registered: cardRegistered,
        shipping_information: shippingFormik.values,
        shipping_cost: shippingCost,
        products_quantities: retrieveProductsQuantities(),
        products_to_subscribe: productsToSubscribe,
      },
    };
    let request;
    if (productId) {
      request = buyerApi.payables.payProduct({ ...args, product_id: productId });
    } else if (groupId) {
      request = buyerApi.payables.payGroup({ ...args, group_id: groupId });
    } else if (singlePaymentBuyerId) {
      request = buyerApi.payables.paySinglePaymentBuyer({
        ...args,
        single_payment_buyer_id: singlePaymentBuyerId,
      });
    } else if (subscriptionBuyerId) {
      request = buyerApi.payables.paySubscriptionBuyer({
        ...args,
        subscription_buyer_id: subscriptionBuyerId,
      });
    } else if (paymentPlanId) {
      request = buyerApi.payables.payPaymentPlan({ ...args, payment_plan_id: paymentPlanId });
    } else if (subscriptionId) {
      if (suggestionSelected) {
        request = buyerApi.payables.paySubscriptionBuyer({
          ...args,
          subscription_buyer_id: suggestionSelected.id,
        });
      } else {
        request = buyerApi.payables.paySubscription({ ...args, subscription_id: subscriptionId });
      }
    } else if (singlePaymentId) {
      request = buyerApi.payables.paySinglePayment({ ...args, single_payment_id: singlePaymentId });
    } else if (checkoutId) {
      request = buyerApi.payables.payCheckout({ ...args, checkout_id: checkoutId });
    } else {
      return;
    }
    request
      .then((body: any) => {
        if (body.data?.redirect) {
          body.data?.redirect_to
            ? history.push(body.data?.redirect_to)
            : history.push('/subscription/success?delayed_wp=true');
        }
        props.setSelectedPaymentMethod(body.data);
      })
      .catch((err: any): void => {
        Sentry.captureException(err);
        if (err?.response?.status === 400) {
          if (err.response.data.message === 'existing_pac') {
            enqueueSnackbar(
              'Ya tienes un PAC regsitrado, ingresa al portal del cliente para administrarlos',
              { variant: 'warning' }
            );
          }
          setErrors(err.response.data?.meta?.errors);
          Object.entries(err.response.data?.meta?.errors || {}).forEach(
            (error: [string, unknown]) => {
              if (typeof error[1] == 'string') {
                enqueueSnackbar(error[1], { variant: 'warning' });
              }
            }
          );
          if (err.response.data?.data?.approved === false) {
            err.response.data?.data?.redirect === true
              ? history.push(err.response.data?.data?.redirect_to)
              : history.push('/subscription/error');
          }
        } else {
          enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
        }
      })
      .finally(() => setLoadingPayment(false));
  };

  const formik = useFormik<Partial<BuyerInfo>>({
    initialValues: {
      payment_method: '',
      id: payable?.buyer?.id || '',
      name: payable?.buyer?.name || '',
      last_name: payable?.buyer?.last_name || '',
      email: payable?.buyer?.email || '',
      extra_fields: payable?.buyer_extra_fields || {},
    },
    validationSchema: () => buyerSchema,
    onSubmit: submitForm,
  });

  const addShippingInformation = (shippingInfo: ShippingInformation) => {
    formik.setFieldValue('shipping_information', shippingInfo);
  };

  const shippingFormik = useFormik<ShippingInformation>({
    initialValues: {
      address1: payable?.shipping_address?.address1 || '',
      address2: '',
      country: 'Chile',
      commune: payable?.shipping_address?.commune || '',
      region: payable?.shipping_address?.region || '',
      phone: '',
    },
    validationSchema: () => (payable?.ask_shipping_address ? buyerShippingSchema : shippingSchema),
    onSubmit: addShippingInformation,
  });

  useEffect(() => {
    formik.setFieldValue('extra_fields', payable?.buyer_extra_fields || {});
  }, [payable]);

  useImperativeHandle(props.externalFormRef, () => ({
    submitForm(payment_method) {
      formik.setFieldValue('payment_method', payment_method).then(() => formik.submitForm());
    },
  }));

  const retrieveShippingValues = () => {
    if (payable?.payment_link_configuration?.free_shipping || payable?.ask_shipping_address) return;
    setDisableNextStep(true);
    setLoadingShippingCost(true);
    const quantities = payable?.payable_products
      ?.map((prod: SubscriptionProduct | SinglePaymentProduct | CheckoutProduct) => ({
        id: prod.id,
        quantity: prod.quantity,
      }))
      .reduce((prev: { [key: string]: number }, curr) => {
        prev[curr.id] = curr.quantity;
        return prev;
      }, {});
    buyerApi.shippingRates
      .shippingRate({
        country: shippingFormik.values.country,
        region: shippingFormik.values.region,
        commune: shippingFormik.values.commune,
        origin: payable?.integration_kind,
        quantities: quantities,
        model: subscriptionId ? 'Subscription' : 'Checkout',
        model_id: subscriptionId || checkoutId,
        company_id: payable?.company.id,
      })
      .then((value) => {
        setShippingCost(value.shipping_rate);
      })
      .catch((reason) => {
        if (reason.response.data.message === 'shipping method not found') {
          enqueueSnackbar('No pudimos obtener el costo de despacho, intenta nuevamente', {
            variant: 'error',
          });
        }
      })
      .finally(() => {
        setDisableNextStep(false);
        setLoadingShippingCost(false);
      });
  };

  useEffect(() => {
    if (shippingFormik.values.region && shippingFormik.values.commune) {
      retrieveShippingValues();
    }
  }, [shippingFormik.values.commune, basketAmount]);

  useEffect(() => {
    if (payable?.ask_shipping_address) return;
    if (shippingFormik.values.region && shippingFormik.values.commune) {
      shippingFormik.setFieldValue('commune', '');
    }
  }, [shippingFormik.values.region]);

  const calcShippingValueForProducts = () => {
    if (!shippingFormik.values.region || !shippingFormik.values.commune) {
      return;
    }

    if (shippingQueryTimeout) clearTimeout(shippingQueryTimeout);

    shippingQueryTimeout = setTimeout(() => {
      retrieveShippingValues();
    }, 1000);
  };

  useEffect(() => {
    if (payable?.buyer) {
      Object.entries(payable?.buyer).forEach(([key, value]) => {
        if (
          Object.prototype.hasOwnProperty.call(formik.values, key) &&
          value &&
          key == (key as keyof Partial<BuyerInfo>) &&
          typeof payable?.buyer?.[key as keyof Partial<BuyerInfo>] == typeof value
        ) {
          formik.setFieldValue(key, value);
        }
      });
    }
    if (payable?.shipping_address) {
      Object.entries(payable?.shipping_address).forEach(([key, value]) => {
        if (
          Object.prototype.hasOwnProperty.call(shippingFormik.values, key) &&
          value &&
          key == (key as keyof Partial<ShippingInformation>) &&
          typeof payable?.shipping_address?.[key as keyof Partial<ShippingInformation>] ==
            typeof value
        ) {
          shippingFormik.setFieldValue(key, value);
        }
      });
    }
  }, [payable?.buyer]);

  const validateExtraFields = () => {
    const mefs = Object.keys(payable?.mandatory_extra_fields || {}).filter(
      (key) => payable?.mandatory_extra_fields?.[key]
    );
    const missing = mefs.filter(
      (key) =>
        !(
          key in (formik.values.extra_fields || {}) &&
          ![null, '', undefined].includes((formik.values.extra_fields || {})[key])
        ) ||
        (downcase(payable?.extra_fields?.[key]) === 'rut' &&
          formik.values.extra_fields?.[key] &&
          !validateRut(formik.values.extra_fields[key]))
    );
    if (missing.length > 0) {
      missing.forEach((ef) =>
        formik.setFieldError(
          `extra_fields.${ef}`,
          `${payable?.extra_fields?.[ef]} ${
            downcase(payable?.extra_fields?.[ef]) === 'rut' ? 'inválido' : 'es un campo obligatorio'
          }`
        )
      );
      return false;
    }
    return true;
  };

  const checkSimilarSubscriptions = () => {
    if (allowCustomCheckout) return setActiveStep(steps[steps.indexOf(activeStep) + 1]);
    if (subscriptionId) {
      if (suggestionOffered) {
        setActiveStep(steps[steps.indexOf(activeStep) + 1]);
      } else {
        setLoadingPayment(true);
        buyerApi.subscriptions
          .similarSubscriptionBuyers(
            subscriptionId,
            formik.values.email || '',
            formik.values.extra_fields || {}
          )
          .then((data: ApiList<Suggestion>) => {
            if (data.data.length > 0) {
              setBuyerSuggestion(true);
              setPossibleBuyersToDisplay(data.data);
            } else {
              setActiveStep(steps[steps.indexOf(activeStep) + 1]);
            }
          })
          .finally(() => setLoadingPayment(false));
      }
    } else {
      setActiveStep(steps[steps.indexOf(activeStep) + 1]);
    }
  };

  const checkShippingCost = () => {
    if (allowCustomCheckout) {
      if (shippingCost !== null) {
        return true;
      } else {
        enqueueSnackbar('No pudimos obtener el costo de despacho, intenta nuevamente', {
          variant: 'error',
        });
        return false;
      }
    } else {
      return true;
    }
  };

  const nextStep = async () => {
    const auxObject: { [key: string]: string | null } = {};
    if (
      payable?.modificable_recurrence ||
      (checkoutId && productsToSubscribe && productsToSubscribe?.length > 0)
    ) {
      customRecurrence === null &&
        (auxObject['recurrence' as keyof typeof auxObject] = 'Debes seleccionar una recurrencia');
      [null, NaN, 0].includes(customRecurrenceAmount) &&
        customRecurrence === 'Personalizada' &&
        (auxObject['amount' as keyof typeof auxObject] = 'Debes seleccionar la periodicidad');
      setCustomRecurrenceErrors(auxObject);
      customRecurrence === 'Personalizada' &&
        customRecurrenceAmount &&
        (customRecurrenceAmount < 7 || customRecurrenceAmount > 90) &&
        (auxObject['amount' as keyof typeof auxObject] = 'Mínimo 7 días y máximo 90');
      setCustomRecurrenceErrors(auxObject);
    }
    let validFormik: boolean | undefined = true;
    if (formikToValidate) {
      validFormik = await formikIsValid();
      const validEFs = validateExtraFields();
      if (!validFormik || !validEFs) return;
    }
    formik.validateForm().then((result: FormikErrors<Partial<BuyerInfo>>) => {
      Object.keys(result).length > 0
        ? formik.submitForm()
        : validFormik &&
          validateExtraFields() &&
          Object.keys(auxObject).length === 0 &&
          checkShippingCost()
        ? checkSimilarSubscriptions()
        : undefined;
    });
    navigateTop(0, 0, 'smooth');
  };

  const prevStep = () => {
    setActiveStep(steps[steps.indexOf(activeStep) - 1]);
  };

  useEffect(() => {
    if (payable?.company) setDocumentTitle(`${payable?.company?.name} | Link de pago`);
  }, [payable?.company]);

  const selectBuyer = (suggestions: Suggestion[], selection: string | null) => {
    if (formik.values.name) {
      setPreviousName(formik.values.name);
    }
    const resource = selection
      ? suggestions.filter((suggestion) => suggestion.id === selection)[0]
      : suggestions[0];
    formik.setFieldValue('name', resource.buyer_name);
    formik.setFieldValue('email', resource.buyer_email);
    formik.setFieldValue('id', resource.buyer_id);
    formik.setFieldValue('extra_fields', resource.extra_fields);
    formik.setFieldValue('subscription_id', resource.id);
    setSuggestionSelected(resource);
    TagManager.dataLayer({
      dataLayer: { event: 'accepted_similar_subscription', company_id: payable?.company?.id },
    });
    setSuggestionOffered(true);
  };

  const abortSelectedSuggestion = (param: any) => {
    formik.setFieldValue('subscription_id', null);
    formik.setFieldValue(
      'extra_fields',
      mapValues(payable?.extra_fields, () => '')
    );
    if (previousName) {
      formik.setFieldValue('name', previousName);
    }
    setSuggestionSelected(param === 'setNull' ? null : undefined);
    setSubscriptionSelection(null);
    setSuggestionOffered(false);
  };

  const validateDiscountCode = (event: any) => {
    if (payable && allowCustomCheckout && event.target.value.length > 0) {
      setLoadingDiscount(true);
      buyerApi.subscriptions
        .validateDiscount(payable.company.id, event.target.value)
        .then((data: GenericObject) => {
          if (data.valid) {
            setValidExtraDiscount(true);
            setExtraDiscount(data);
          } else {
            setValidExtraDiscount(false);
            setExtraDiscount(undefined);
          }
        })
        .catch(() =>
          enqueueSnackbar('No pudimos validar el descuento, intenta nuevamente', {
            variant: 'error',
          })
        )
        .finally(() => setLoadingDiscount(false));
    }
  };

  useEffect(
    () =>
      TagManager.dataLayer({
        dataLayer: { event: 'client_payment_view', company_id: payable?.company?.id },
      }),
    [payable?.company]
  );

  useEffect(() => {
    if (buyerSuggestionOpen) {
      TagManager.dataLayer({
        dataLayer: { event: 'similar_subscriptions_popup', company_id: payable?.company?.id },
      });
    }
  }, [buyerSuggestionOpen]);

  useEffect(() => {
    if (formik.values.payment_method && isMobile) {
      setTimeout(() => {
        const errorField = document.querySelector('.Mui-error');
        if (errorField) {
          document.getElementById('clientForm')?.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  }, [formik.values.payment_method]);

  useEffect(() => {
    if (payable?.default_payment_methods['single']) {
      setCurrPaymentMethod(payable.default_payment_methods['single']);
    } else if (payable?.default_payment_methods['card']) {
      setCurrPaymentMethod(payable.default_payment_methods['card']);
    }
  }, [payable]);

  useEffect(() => {
    if (customRecurrence) {
      setCustomRecurrenceErrors(omit(customRecurrenceErrors, 'recurrence'));
    }
  }, [customRecurrence]);

  useEffect(() => {
    if (customRecurrenceAmount) {
      setCustomRecurrenceErrors(omit(customRecurrenceErrors, 'amount'));
    }
  }, [customRecurrenceAmount]);

  useEffect(() => {
    if (payable?.payment_link_configuration) {
      shippingFormik.setFieldValue(
        'region',
        payable?.payment_link_configuration.shipping_one_region
          ? payable?.payment_link_configuration.shipping_regions[0]
          : ''
      );
      if (payable?.payment_link_configuration.free_shipping) setShippingCost(0);
    }
  }, [payable?.payment_link_configuration]);

  return (
    <>
      <div className={styles.sellerContainer}>
        <Container maxWidth={false} className={styles.containerWidth}>
          {loading && (
            <div className={styles.loaderContainer}>
              <img
                src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_color.png"
                alt="Logo Zafepay"
              />
            </div>
          )}

          <Fragment>
            <div className={styles.containerPayment}>
              <Avatar
                className={styles.sellerHeaderAvatar}
                alt={payable?.company?.name}
                img={payable?.company?.image}
                context="company"
              />
              <div className={styles.stepperContainer}>
                <Stepper activeStep={steps.indexOf(activeStep)} nonLinear>
                  <Step
                    completed={steps.indexOf(activeStep) > 0}
                    sx={{
                      text: {
                        fill: `${
                          steps.indexOf(activeStep) == 0 && payable?.company.payment_design
                            ? payable?.company.payment_design?.background_color
                            : '#4653E3'
                        } !important`,
                      },
                      svg: {
                        borderColor: `${
                          steps.indexOf(activeStep) == 0
                            ? payable?.company.payment_design
                              ? payable?.company.payment_design?.background_color
                              : '#4653E3'
                            : 'white'
                        } !important`,
                        color: `${
                          steps.indexOf(activeStep) == 0
                            ? 'white'
                            : payable?.company.payment_design
                            ? payable?.company.payment_design?.background_color
                            : '#4653E3'
                        } !important`,
                      },
                    }}
                  >
                    <StepLabel />
                  </Step>
                  <Step
                    completed={steps.indexOf(activeStep) > steps.indexOf('payment')}
                    sx={{
                      text: {
                        fill: `${
                          steps.indexOf(activeStep) >= steps.indexOf('payment')
                            ? payable?.company.payment_design
                              ? payable?.company.payment_design?.background_color
                              : '#4653E3'
                            : '#838383'
                        } !important`,
                      },
                      svg: {
                        borderColor: `${
                          steps.indexOf(activeStep) >= steps.indexOf('payment')
                            ? payable?.company.payment_design
                              ? payable?.company.payment_design?.background_color
                              : '#4653E3'
                            : '#838383'
                        } !important`,
                        color: 'white !important',
                      },
                    }}
                  >
                    <StepLabel />
                  </Step>
                </Stepper>
              </div>
              {activeStep == 'buyer_data' ? (
                allowCustomCheckout && payable ? (
                  <ShopifyOrder
                    formik={formik}
                    shippingFormik={shippingFormik}
                    payable={payable}
                    setPayable={setPayable}
                    nextStep={nextStep}
                    customRecurrenceProps={{
                      selectedRecurrence: customRecurrence,
                      setSelectedRecurrence: setCustomRecurrence,
                      amount: customRecurrenceAmount,
                      setAmount: setCustomRecurrenceAmount,
                      errors: customRecurrenceErrors,
                    }}
                    shippingCost={shippingCost}
                    setChangedQty={calcShippingValueForProducts}
                    disabledContinueButton={disableNextStep}
                    loadingStock={loadingStock}
                    loadingShippingCost={loadingShippingCost}
                    validateDiscountCode={validateDiscountCode}
                    loadingDiscount={loadingDiscount}
                    extraDiscount={extraDiscount}
                    validExtraDiscount={validExtraDiscount}
                    allowCustomCheckout={allowCustomCheckout}
                    productsToSubscribeState={{
                      state: productsToSubscribe,
                      setState: setProductsToSubscribe,
                    }}
                    codeDiscountState={{
                      state: codeDiscountValue,
                      setState: setCodeDiscountValue,
                    }}
                  />
                ) : (
                  <div
                    className={
                      !payable?.many_products && !isMobile
                        ? styles.horizontalContainer
                        : styles.verticalContainer
                    }
                  >
                    {!allowCustomCheckout && (
                      <Fragment>
                        <FormSelector
                          payable={payable}
                          formik={formik}
                          shippingFormik={shippingFormik}
                          shippingCost={shippingCost}
                          suggestionState={{
                            state: suggestionSelected,
                            setState: setSuggestionSelected,
                          }}
                          abortSelectedSuggestion={abortSelectedSuggestion}
                          loadingShippingCost={loadingShippingCost}
                        />
                      </Fragment>
                    )}
                    <div className={styles.productSummary}>
                      <PaymentSummary
                        payable={payable}
                        basketAmount={basketAmount}
                        setBasketAmount={setBasketAmount}
                        customRecurrenceProps={{
                          selectedRecurrence: customRecurrence,
                          setSelectedRecurrence: setCustomRecurrence,
                          amount: customRecurrenceAmount,
                          setAmount: setCustomRecurrenceAmount,
                          errors: customRecurrenceErrors,
                        }}
                        shippingCost={shippingCost}
                        allowCustomCheckout={allowCustomCheckout}
                      />
                      <div className={styles.kioskFooter}>
                        <Typography align="center">
                          Cualquier duda contactános en{' '}
                          <a href={`mailto:${payable?.contact_email}`} style={{ color: '#1f1f1f' }}>
                            {payable?.contact_email}
                          </a>{' '}
                          {payable?.contact_phone && ` o al ${payable?.contact_phone}`}
                        </Typography>
                      </div>
                    </div>
                  </div>
                )
              ) : allowCustomCheckout && payable ? (
                <ShopifyPayment
                  formik={formik}
                  shippingFormik={shippingFormik}
                  payable={payable}
                  setPayable={setPayable}
                  cardRegistered={cardRegistered}
                  setCurrPaymentMethod={setCurrPaymentMethod}
                  currPaymentMethod={currPaymentMethod}
                  setCardRegistered={setCardRegistered}
                  selectPaymentMethod={props.selectPaymentMethodForApproval}
                  mercadoPagoRef={props.extraArgs?.mercadoPagoRef}
                  mpEmailRef={mpEmailRef}
                  mpIssuer={props.extraArgs?.mpIssuer}
                  mpLoaded={props.extraArgs?.mercadoPagoLoaded}
                  loadingPayment={loadingPayment}
                  setCardInfo={props.setCardInfo}
                  kushkiTokenFail={props.kushkiTokenFail}
                  setLoadingPayment={setLoadingPayment}
                  customRecurrenceProps={{
                    selectedRecurrence: customRecurrence,
                    setSelectedRecurrence: setCustomRecurrence,
                    amount: customRecurrenceAmount,
                    setAmount: setCustomRecurrenceAmount,
                    errors: customRecurrenceErrors,
                  }}
                  prevStep={prevStep}
                  shippingCost={shippingCost}
                  extraDiscount={extraDiscount}
                  productsToSubscribeState={{
                    state: productsToSubscribe,
                    setState: setProductsToSubscribe,
                  }}
                />
              ) : (
                <PaymentGateways
                  payable={payable}
                  cardRegistered={cardRegistered}
                  setCurrPaymentMethod={setCurrPaymentMethod}
                  currPaymentMethod={currPaymentMethod}
                  setCardRegistered={setCardRegistered}
                  selectPaymentMethod={props.selectPaymentMethodForApproval}
                  mercadoPagoRef={props.extraArgs?.mercadoPagoRef}
                  mpEmailRef={mpEmailRef}
                  mpIssuer={props.extraArgs?.mpIssuer}
                  mpLoaded={props.extraArgs?.mercadoPagoLoaded}
                  loadingPayment={loadingPayment}
                  setCardInfo={props.setCardInfo}
                  kushkiTokenFail={props.kushkiTokenFail}
                  setLoadingPayment={setLoadingPayment}
                  enrollPayment={query.enroll_payment}
                />
              )}
              {allowCustomCheckout ? null : steps.indexOf(activeStep) > 0 ? (
                <Button
                  variant="outlined"
                  onClick={prevStep}
                  className={styles.payButton}
                  style={{
                    backgroundColor: 'white',
                    color: payable?.company?.payment_design?.background_color || '#4653E3',
                    borderColor: payable?.company?.payment_design?.background_color,
                  }}
                  disabled={loadingPayment}
                >
                  Volver
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={nextStep}
                  className={styles.payButton}
                  style={{
                    backgroundColor: loadingPayment
                      ? '#b9b9b9'
                      : payable?.company?.payment_design?.background_color || '#4653E3',
                  }}
                  disabled={loadingPayment}
                >
                  {query.enroll_payment ? 'Continuar' : 'Ir a pagar'}
                </Button>
              )}
            </div>
          </Fragment>
        </Container>
        <BuyerSuggestion
          payable={payable}
          state={{ open: buyerSuggestionOpen, setOpen: setBuyerSuggestion }}
          suggestions={buyersToDisplay}
          acceptSuggestion={selectBuyer}
          abortSelectedSuggestion={abortSelectedSuggestion}
          setSuggestionOffered={setSuggestionOffered}
          subscriptionState={{
            state: selectedBuyerSubscription,
            setState: setSubscriptionSelection,
          }}
        />
        <div ref={bottomRef}></div>
      </div>
    </>
  );
};

export default AddPaymentMethods(Payment);
